import React, { useRef } from "react";
import PageSection from "./PageSection";
import Image from "./Image";
import Chalkboard from "../images/lecture.webp";
import { motion, useScroll, useTransform } from "framer-motion";

const About = () => {
  const ref = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end start", "end end"],
  });

  console.log(scrollYProgress);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.4,
      },
    },
  };
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  return (
    <PageSection name="about" className="mt-0 bg-whitesmoke">
      <motion.div
        ref={ref}
        initial={{ x: -200 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.3 }}
        style={{ opacity: scrollYProgress }}
        className="row flex-lg-row-reverse align-items-center justify-content-center shadow-lg p-5 bg-white"
      >
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="col-lg-6"
        >
          <motion.h1 variants={item} className="display-1 mb-1 lh-1">
            Maths Tutoring
          </motion.h1>
          <motion.p variants={item} className="lead mb-2">
            Any Topic. Any Time.
          </motion.p>
          <motion.a
            variants={item}
            className="btn btn-outline-primary"
            href="#contact"
          >
            Contact me
          </motion.a>
        </motion.div>
        <Image
          src={Chalkboard}
          description="Avi writing on chalkboard"
          width="450"
          caption="University of Warwick, Master of Mathematics. First Class."
        />
      </motion.div>
    </PageSection>
  );
};

export default About;
