import React, { useState } from "react";
import { Alert } from "react-bootstrap";

function SubmitButton({ submitForm, disabledButton }) {
  const [show, setShow] = useState(false);
  const onClick = () => {
    setShow(true);
    submitForm();
  };

  if (show) {
    return (
      <Alert show={show} variant="success">
        <Alert.Heading>Thank you!</Alert.Heading>
        Your message has been submitted. I will get back to you within a few
        hours.
      </Alert>
    );
  }
  return (
    <button
      type="button"
      className="btn btn-outline-primary"
      onClick={onClick}
      disabled={disabledButton}
    >
      Submit
    </button>
  );
}

export default SubmitButton;
