import React from 'react'

const PricingCard = ({level, price}) => {
  return (
    <div className="col-12 col-md-4 card mb-3 p-0">
        <div className="card-header text-center">{level}</div>
        <div className="card-body">
            {`£${price}`}
        </div>
    </div>  
)
}

export default PricingCard