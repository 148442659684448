import React from 'react'

const TutoringInfoCard = ({header, items}) => {
  return (
    <div className="col-12 col-md-4 card mb-3 p-0">
        <div className="card-header">{header}</div>
        <div className="card-body">
            <ul className="list-group">
                {items.map(x=><li key={x} className="list-group-item">{x}</li>)}
            </ul>
        </div>
    </div>
  )
}

export default TutoringInfoCard