import React from "react";
import PageSection from "./PageSection";
import Heading from "./Heading";
import PricingCard from "./PricingCard";

const Pricing = () => {
  return (
    <PageSection name="pricing" className="d-flex flex-column">
      <div className="row">
        <Heading title="Pricing" subtitle="Starting hourly rates by level" />
        <PricingCard level="A-Level" price="40*" />
        <PricingCard level="GCSE" price="30" />
        <PricingCard level="Primary" price="20" />
      </div>
      <p>*Further maths is an additional £5.</p>
    </PageSection>
  );
};

export default Pricing;
