import Navbar from './components/Navbar'
import Contact from './components/Contact'
import Testimonials from './components/Testimonials'
import Tutoring from './components/Tutoring'
import Pricing from './components/Pricing'
import About from './components/About'



const App = () => {
    return (
        <div className='container'>
            <header>
                <Navbar/>
            </header>
            <main>
                <About/>
                <Tutoring/>
                <Testimonials/>
                <Pricing/>
                <Contact/>
            </main>
        </div>
    )
}

export default App