import React from 'react'
import PageSection from './PageSection'
import Image from './Image'
import TutoringInfoCard from './TutoringInfoCard'
import Heading from './Heading'
import Computer from '../images/computer.webp'


const Tutoring = () => {
  return (
    <PageSection name="tutoring">
        <div className='row'>
            <Heading title="How it works" subtitle="Arrange a free 15 minute video call today."></Heading>
            <TutoringInfoCard header="Any Level" items={["Primary", "GCSE", "A-Level"]}/>
            <TutoringInfoCard header="Any Topic" items={["Integration", "Vectors", "Statistics", "..."]}/>
            <TutoringInfoCard header="Any Exam Board" items={["AQA", "Edexcel", "OCR"]}/>
        </div>
        <div className='row flex-column-reverse flex-lg-row-reverse align-items-center justify-content-center p-5'> 
            <Image src={Computer} description="Computer on desk" width="1000"/>
            <div className="col-lg-6">
                <p className='lead'>All online.</p>
            </div>
        </div>
    </PageSection> 

)
}

export default Tutoring