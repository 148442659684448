import React from 'react'
import PageSection from './PageSection'
import Heading from './Heading'
import  Carousel  from 'react-bootstrap/Carousel'
import TestimonialQuote from './TestimonialQuote'
import testimonials from '../testimonials'

const Testimonials = () => {
  return (
    <PageSection name="testimonials" className="d-flex flex-column">
    <div className="row">
        <Heading title = "What others are saying"/>
        <Carousel data-bs-theme="dark">
            {testimonials.map(t=>(
                <Carousel.Item className="px-5" key={t.id}>
                    <TestimonialQuote quote={t.quote} studentName={t.studentName} yearGroup={t.yearGroup}/>
                </Carousel.Item>
            ))}


        </Carousel>
    </div>
</PageSection>
  )
}

export default Testimonials