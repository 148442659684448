import React from 'react'

const PageSection = ({name, className, children}) => {
  return (
    <section className={`container text-center min-vh-100 p-3 ${className} align-items-center justify-content-center`} id={`${name}`}>
        {children}
    </section>
    )
}

export default PageSection