import React from 'react'

const TestimonialQuote = ({quote, studentName, yearGroup}) => {
  return (
    <figure className="text-center p-2">
    <blockquote className="blockquote">
        <p className="mb-2">{quote}</p>
    </blockquote>
    <figcaption className="blockquote-footer">
        {studentName}{', Year '}{yearGroup}
    </figcaption>
</figure>
  )
}

export default TestimonialQuote