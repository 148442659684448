import { useRef, useState } from "react";
import PageSection from "./PageSection";
import Heading from "./Heading";

import { db } from "../firebaseConfig";
import { addDoc, collection } from "firebase/firestore";
import SubmitButton from "./SubmitButton";
import { motion, useScroll } from "framer-motion";

const Contact = () => {
  const ref = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const [details, setDetails] = useState({ name: "", email: "", message: "" });

  const disabledButton = !details.name || !details.email || !details.message;

  const onChange = (event) => {
    setDetails({ ...details, [event.target.name]: event.target.value });
  };

  const onSubmit = async (e) => {
    try {
      console.log(details);
      //   await addDoc(collection(db, "contacts"), details);
      const url =
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/send-email"
          : "https://tutoring-backend-pj2z.onrender.com/send-email";
      await fetch(url, {
        method: "POST",
        body: JSON.stringify(details),
        headers: { "Content-Type": "application/json" },
      });
    } catch (e) {
      console.error("Error sending email", e);
    }
  };
  return (
    <PageSection
      name="contact"
      className="shadow-lg d-flex flex-column bg-white"
    >
      <motion.div className="row gy-4" style={{ opacity: scrollYProgress }}>
        <Heading title="Contact me" subtitle="Drop me a message" />
        <form className="col-12">
          <fieldset className="container">
            <div className="row">
              <div className="form-group col-12 mt-2">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={details.name}
                  onChange={onChange}
                  maxLength={100}
                />
              </div>
              <div className="form-group col-12 mt-2">
                <label htmlFor="email">Your email</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  value={details.email}
                  onChange={onChange}
                  maxLength={200}
                />
              </div>
              <div className="form-group col-12 mt-2">
                <label htmlFor="exampleTextarea" className="form-label">
                  Your message
                </label>
                <textarea
                  className="form-control"
                  id="exampleTextarea"
                  rows="6"
                  name="message"
                  value={details.message}
                  onChange={onChange}
                  placeholder="Let me know the year you are in, the topics you would like help with, how often you would like lessons or any other questions you have."
                  maxLength={1500}
                ></textarea>
              </div>
              <div className="form-group col-12 mt-4">
                <SubmitButton
                  submitForm={onSubmit}
                  disabledButton={disabledButton}
                />
              </div>
            </div>
          </fieldset>
        </form>
      </motion.div>
    </PageSection>
  );
};

export default Contact;
