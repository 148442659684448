import React from 'react'

const Image = ({src, description, width, caption}) => {
  return (
    <div className="col-10 col-sm-8 col-lg-6 mt-4 mt-lg-0">
      <figure>
        <img className='d-block mx-lg-auto img-fluid' src={src} alt={description} loading='lazy' width={width}/>
        <figcaption>{caption}</figcaption>
        </figure>
    </div>
  )
}

export default Image