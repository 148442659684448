import React from "react";
import { motion, useScroll } from "framer-motion";

const Heading = ({ title, subtitle }) => {
  const offscreen = { y: 70, opacity: 0 };
  const onscreen = {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  };

  return (
    <motion.div className="col-12" initial={offscreen} whileInView={onscreen}>
      <h2 className="display-6 my-2 lh-1">{title}</h2>
      {subtitle && <p className="lead">{subtitle}</p>}
    </motion.div>
  );
};

export default Heading;
